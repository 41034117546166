import { flagPayloads } from '~/services/posthog'

export const getFlagPayload = (featureKey: string) => {
  return flagPayloads[featureKey]
}

export const hasEditRecordVarietyField = getFlagPayload('edit-record-variety-field')
export const hasPodCsvUploadDownload = getFlagPayload('pod-csv-upload-download')
export const hasPremiumContracts = getFlagPayload('premium-contracts')
export const hasNewInventoryLite = getFlagPayload('new-inventory-lite')
export const hasPodDataDownloadTimeframe = getFlagPayload('pod-data-download-timeframe')
export const hasWantedListingEnhancements = getFlagPayload('wanted-listing-enhancements')
export const hasMenuPostListingButton = getFlagPayload('menu-post-listing-button')
export const hasTradingWelcomeBanner = getFlagPayload('trading-welcome-banner')
export const hasBuyerRedesignTradeSummary = getFlagPayload('buyer-redesign-trade-summary')
export const hasBuyerDetailsPageRedesign = getFlagPayload('buyer-listing-details-page-redesign')
export const hasAccessPendingAccount = getFlagPayload('access-pending-account')
export const hasBuyerListingDetailsPageRedesign = getFlagPayload('buyer-listing-details-page-redesign')
export const hasFileUploadRefactor = getFlagPayload('file-upload-refactor')
export const hasGettingStarted = getFlagPayload('getting-started')
export const hasPricePrediction = getFlagPayload('price-prediction')
export const hasPendingAccountListing = getFlagPayload('pending-account-listing')
export const updateOrgWithinPostListing = getFlagPayload('update-org-within-post-listing')
export const hasRemoveNationwideOption = getFlagPayload('remove-nationwide-option')
export const hasUpdateOrgWithinPostListing = getFlagPayload('update-org-within-post-listing')
export const hasAutoEndListings = getFlagPayload('auto-end-listings')
export const hasBuyerPriceOfferSellerName = getFlagPayload('buyer-price-offer-seller-name')
export const hasDefaultListingMinTonnage = getFlagPayload('default-listing-min-tonnage')
export const hasMarketingCommunicationsUpdate = getFlagPayload('market-opt-in-updates')
export const hasShowUnsavedChangesModal = getFlagPayload('show-unsaved-changes-modal')
export const hasTermsAndConditionsUpdate = getFlagPayload('terms-and-conditions-update')
export const hasSignupPurpose = getFlagPayload('signup-purpose')
export const hasSellerMarketComparison = getFlagPayload('seller-market-comparison')
export const hasMarketingQuestion = getFlagPayload('show-marketing-question')
export const hasAutoEndV2 = getFlagPayload('auto-end-v2')
export const hasClosedBidsStatusUpdate = getFlagPayload('closed-bids-status-update')
export const hasLoadingSkeletons = getFlagPayload('loading-skeletons')
export const hasHarvestYearAttributeInputUpdates = getFlagPayload('harvest-year-attribute-input-updates')
export const hasMarketInsightsHighlightsCarousel = getFlagPayload('market-insights-highlights-carousel')
export const hasDraftListings = getFlagPayload('draft-listings')
export const hasBuyerAssuranceUpdate = getFlagPayload('buyer-assurance-update')
export const hasMovementComponentPostListing = getFlagPayload('movement-component-post-listing')
export const hasBuyerHideOrgToggle = getFlagPayload('buyer-hide-org-toggle')
export const hasVarietyInputUpdate = getFlagPayload('variety-input-update')
export const hasAsAvailable = getFlagPayload('as-available')
export const hasAppWebForcedLogout = getFlagPayload('app-web-forced-logout')
export const hasAdvancePayCalculatorRefactor = getFlagPayload('advance-pay-calculator-refactor')
export const hasListingSupportComponent = getFlagPayload('listing-support-component')
export const hasCommoditySearch = getFlagPayload('commodity-fuzzy-search')
export const hasTargetPricesExplainer = getFlagPayload('target-prices-explainer')
export const hasTermsAndConditionsModalRefactor = getFlagPayload('terms-and-conditions-modal-refactor')
export const hasListingsRecentCommodities = getFlagPayload('listings-recent-commodities')
export const hasBuyerFormRedesign = getFlagPayload('buyer-form-redesign')
export const hasUpdateLoginImages = getFlagPayload('update-login-images')
export const hasTargetPricesCalculate = getFlagPayload('target-prices-calculate')
export const hasRegionSelectorAlertPreferences = getFlagPayload('region-selector-alert-preferences')
export const hasListingBasePlusPremiumOption = getFlagPayload('listings-base-plus-premium-option')
export const hasSellerBuyerMenuUpdates = getFlagPayload('seller-buyer-menu-update')
export const hasPasswordSecurityRulesUpdate = getFlagPayload('password-security-rules-update')
export const hasListingClosedPrompt = getFlagPayload('listing-closed-prompt')
export const hasMagicSignInFlow = getFlagPayload('magic-sign-in-flow')
export const hasBuyerListingsAllWanted = getFlagPayload('buyer-listings-all-wanted')
export const hasBuyerAlertBidSameOrg = getFlagPayload('buyer-alert-bid-same-org')
