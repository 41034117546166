import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a287ea7e"
import script from "./default.vue?vue&type=script&setup=true&lang=ts"
export * from "./default.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NativeStatusBar: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/layouts/native/status-bar/NativeStatusBar.vue').default,AppLoader: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/layouts/app-loader/AppLoader.vue').default,FloatingNotifications: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/molecules/FloatingNotifications.vue').default,ModalWrapper: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/modals/modal-wrapper/ModalWrapper.vue').default,MobileDropdownMenuOverlay: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/dropdown-menu-list/MobileDropdownMenuOverlay.vue').default,NotificationFloatingWrapper: require('/home/runner/actions-runner/_work/farmto-client/farmto-client/src/components/elements/base/notification/NotificationFloatingWrapper.vue').default})
